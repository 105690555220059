<template>
    <div id="teams" class="fullscreen d-flex align-items-center justify-content-center" v-lazy:background-image="image">
        <div class="fullscreen-inner">
            <b-container>
                <b-row cols-sm="12">
                    <b-col class="py-5">
                        <b-card-group deck class="main-content">
                            <b-card
                            header="the team"
                            >
                                <b-row
                                class="flex-md-row tabs"
                                >
                                    <b-col md="3">
                                        <ul class="tabs__header">
                                            <li class="tabs__header-item"
                                            v-for="(team, index) in notConsultants"
                                            :key="index"
                                            v-on:click="selectedTab(index)"
                                            :class="{active: (activeTab) === index}"
                                            >
                                                <div class="team-tab-inner">
                                                    <p class="team-name">{{ team.name }}</p>
                                                    <p class="team-position small">{{ team.position }}</p>
                                                </div>
                                            </li>
                                            <li class="tabs__header-item"
                                            v-on:click="selectedTab(countNotConsultants)"
                                            :class="{active: (activeTab) === countNotConsultants}"
                                            >
                                                <p class="team-name">Story Consultant & Advisory Board</p>
                                            </li>
                                        </ul>
                                    </b-col>
                                    <b-col md="9">
                                        <div class="tabs__container">
                                            <ul class="tabs__list">
                                                <li
                                                class="tabs__list-tab"
                                                v-for="(team, index) in notConsultants"
                                                :key="index"
                                                v-show="activeTab === index"
                                                >
                                                    <b-row class="flex-md-row-reverse">
                                                        <b-col md="5">
                                                            <img v-lazy="team.thumb">
                                                        </b-col>
                                                        <b-col md="7">
                                                            <h3>{{ team.name }}</h3>
                                                            <h5>{{ team.position }}</h5>
                                                            <h4>Bio</h4>
                                                            <div class="team-bio" v-html="team.bio"></div>
                                                        </b-col>
                                                    </b-row>
                                                </li>
                                                <li
                                                class="tabs__list-tab"
                                                v-show="activeTab === countNotConsultants"
                                                >
                                                    <div class="special-team"
                                                    v-for="(team, index) in consultants"
                                                    :key="index"
                                                    >
                                                        <b-row class="flex-md-row-reverse">
                                                            <b-col md="4">
                                                                <img v-lazy="team.thumb">
                                                            </b-col>
                                                            <b-col md="8">
                                                                <h3>{{ team.name }}</h3>
                                                                <h5>{{ team.position }}</h5>
                                                                <h4>Bio</h4>
                                                                <div class="team-bio" v-html="team.bio"></div>
                                                            </b-col>
                                                        </b-row>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-card>

                        </b-card-group>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
import bg1 from '../images/team-bg.jpeg'
export default {
    data() {
        return {
            activeTab: 0,
            image: bg1,
            teams: [
                {
                    id: 1,
                    name: 'Joya Dass',
                    position: 'Director/ Co-Producer',
                    bio: '<p>Joya is a native of Harrisburg, Pennsylvania. She was born to immigrant parents who came to the US from Calcutta and Myanmar in 1969.  For the last 15 years,  she’s been a TV anchor in New York City, reporting business news from the floor of the New York Stock Exchange for Bloomberg, CNN, and ABC.  She is currently a business anchor with NY1 News.  Additionally, she speaks on behalf of charities dedicated to Indian causes and hosts a weekly  Indian entertainment news show called AVS. Last November, Joya began the second leg of her career.  “First Sight” is her first feature-length documentary. She is currently producing documentaries for the Rockefeller Foundation. Learn more about First Sight at the First Sight blog.</p>',
                    thumb: require('../images/teams/img-16.jpeg'),
                    category: 'main'
                },
                {
                    id: 2,
                    name: 'Douglas Newman',
                    position: 'Executive Producer',
                    bio: '<p>For five years, Douglas worked at ABC News Productions in New York City producing documentaries for the Discovery Channel, the A&E Network, the History Channel and The Learning Channel. Programs on The Human Genome Project, Alan Greenspan, and the Trans-Alaska oil pipeline were among his most recognized achievements. Since 2006, he’s been the Director of Creative Programming at Mouth Watering Media. In this position, he\'s overseen the development of the documentary feature "Gone To Texas: The Lives of Forrest Carter," funded by ITVS and due to air on PBS in the fall 2011, and "Cai\'s Odyssey," a short film documenting the creation of a large scale gunpowder drawing by acclaimed international artist Cai Guo-Qiang and commissioned by the Museum of Fine Arts, Houston.</p><p>Current projects include collaborations with Laura Browder on an untitled feature film about her grandfather, Earl Browder, the former General Secretary of the American Communist Party from 1934-1945, and "Mothers at War", a documentary and multi-media web platform that explores the unique challenges of soldiers who are also mothers. "Mothers at War" is currently in post-production with an expected completion date in mid-2011. Douglas is based in Houston, Texas. To learn more about him, go to <a href="http://www.mouthwateringmedia.com/" target="_blank">www.mouthwateringmedia.com</a>.</p>',
                    thumb: require('../images/teams/DouglasNewman2.jpeg'),
                    category: 'main'
                },
                {
                    id: 3,
                    name: 'Daniella Kahane',
                    position: 'Producer',
                    bio: '<p>Daniella is an award winning writer/director/producer of films such as One Per Cent, Columbia Unbecoming, and most recently Growing Up Barnard. She has also worked for Academy-Award winning producer Wendy Finerman (Forrest Gump, Stepmom, The Devil Wears Prada, P.S. I Love You), and is currently working as producer with Academy-Award nominated filmmaker Edet Belzberg (Underground Children, The Recruiter) on her latest documentary about genocides.</p><p>In 2004, she founded an event consulting and planning group which fed her desire to create charity events that attracted a younger generation of givers. Sensi6 has since produced events that have raised over one million dollars for organizations in need. She graduated summa cum laude with a BA in English and Film Studies from Barnard College, Columbia University.</p>',
                    thumb: require('../images/teams/Daniella.Kahane2.jpg'),
                    category: 'main'
                },
                {
                    id: 4,
                    name: 'Marcelo Bukin',
                    position: 'Director of Photography/ Consulting Producer',
                    bio: '<p>Marcelo Bukin is an award-winning filmmaker specializing in highly visual, character driven, thought-provoking stories. His films have screened in more than 100 international film festivals, broadcast internationally, praised by the press and even by film legend Albert Maysles.</p><p>"Angel\'s Fire" was honored with an IDA Award and selected at DocuWeek&trade; for Academy Awards consideration. His latest film "Dreaming Nicaragua" received the Gold Award Grand Prize at FIPA in France. It was also chosen as "Film of the Month" by Amnesty Intl\' (Movies That Matter) in The Netherlands and premiered to stunning reviews at the Montreal World Film Festival.</p><p>Marcelo graduated in film direction from the Centre d\'Estudis Cinematogràfics de Catalunya in Spain, and has a background in architecture and design. To learn more about him, go to <a href="http://www.bukinfilms.com/" target="_blank">www.bukinfilms.com</a>.</p>',
                    thumb: require('../images/teams/MarceloBukin2.jpeg'),
                    category: 'main'
                },
                {
                    id: 5,
                    name: 'Juan Leguizamon',
                    position: 'Editor',
                    bio: '<p>Juan Manuel Leguizamón is an award winning Film Editor with international experience in Buenos Aires, Barcelona and New York. During his career, Leguizamón has created a distinctive approach to film, mixing narrative, documentary and new media.  His work has been acknowledged and awarded internationally in major film festivals (NY Film Festival, Montreal WFF, Edinburgh, IDFA and IDA Awards among others). To learn more about him, go to <a href="http://www.juanleguizamon.com/" target="_blank">www.juanleguizamon.com</a>.</p>',
                    thumb: require('../images/teams/Juan.Leguizamon.jpg'),
                    category: 'main'
                },
                {
                    id: 6,
                    name: 'Cheriyan John',
                    position: 'Editor',
                    bio: '<p>Cheriyan brings his editing and musical flair to "First Sight" from his work as a producer at MTV.  He’s a graduate of Case Western Reserve University in Cleveland, Ohio.  He met Joya while heading up the in-house broadcast for FTI Consulting. In 2009, Cheriyan travelled to India as part of the Eyes Wide Open Productions Team.  He\'s currently working on a webseries about young Indian Americans living in U.S.</p>',
                    thumb: require('../images/teams/Cheriyan.John.jpg'),
                    category: 'main'
                },
                {
                    id: 7,
                    name: 'Sivamani',
                    position: 'Original Music',
                    bio: '<p>Schooled in both the Carnatic rhythmic tradition and Western Jazz drums, Siva creates magic with everything from conventional cymbals to the vastly unconventional shells, conches and "Biryani Kadai" (a popular cooking vessel in Asia). He inherited his rhythmic brilliance from his father S M Anandan, a well known drummer in the South Indian Film Industry, under whom he started his training. Today, Siva is sought-after performer both for live-shows and recordings. He has played world over with some of the best International artists such as Zakir Hussain, SILK, Asia Electric & Shraddha.  In 2009, music composer A.R. Rahman won a an Oscar for best score for his soundtrack to "Slumdog Millionnaire". Siva\'s percussion work was featured on several songs including "Jai Ho". Today, no world tour of multi-Oscar and multi-Grammy winner A R Rahman is complete without Siva\'s presence.  He is recognized as one of India\'s best drummers. To learn more about him, go to <a href="http://www.sivamani.in/" target="_blank">www.sivamani.in</a>.</p>',
                    thumb: require('../images/teams/Sivamani2.jpeg'),
                    category: 'main'
                },
                {
                    id: 7,
                    name: 'Falu',
                    position: 'Additional Original Music',
                    bio: '<p>Falu is a classically trained Indian vocalist. Her diverse musical repertoire reflects both her traditional Indian roots and a newer inventive rock sound.  She has served as an ambassador of Indian music from Carnegie Hall.  Her live performances have been featured nationally on FOX TV and internationally on National Geographic TV.  Her voice has also been captured in multiple films including Angelina Jolie\'s directorial debut "A Place in Time".</p><p>She was invited to perform at the “Time 100 Gala”  with Slumdog Millionaire composer A.R. Rahman. There she sang in front of the one hundred of Time Magazine\'s most influential people for 2008, which included First Lady Michelle Obama and Oprah Winfrey. Falu performed once again for the First Lady in 2009. She was invited to the White House to sing once again with A.R. Rahman at President Obama\'s first State Dinner in honor of the Indian Prime Minister Manmohan Singh. To learn more about her, go to <a href="http://www.falumusic.com/" target="_blank">Falu Music</a>.</p>',
                    thumb: require('../images/teams/Falu.2.jpg'),
                    category: 'main'
                },
                {
                    id: 8,
                    name: 'Michael Leslie',
                    position: 'Additional Original Music',
                    bio: '<p>Michael J. Leslie is a Los Angeles-based composer and orchestrator for film, television and advertisement. He entered the composing business in 1998 as a writer for radio jingles while attending college in Florida. He now has several theatrical trailers ("Megamind", "The Fighter", "Ninja Assassins" and more), network promos ("Law & Order"), theme park attractions music (Walt Disney\'s "Journey Into Imagination") and over 40 commercial ("Chevy Transformers") and radio jingles under his belt. Michael is a self-taught, yet seasoned, multi-faceted musician of 11 instruments ranging from strings, brass to woodwinds. His creative style and musical influence stems from his upbringing of classical, choral, funk and jazz music. He is equally adept at composing and producing Pop, Urban, World/Ethnic music and many other styles. His diverse musical range has been embraced by various renowned music libraries. Michael\'s music has been heard worldwide on NBC, ABC, FOX, CNN, A&E, TLC, The History Channel and many others. Since embarking on a career as a composer, Michael has been blessed to be mentored by such legends as Quincy Jones and Mike Post. Michael is currently signed to Mike Post Productions and provides underscore cues to NBC\'s "Law & Order" and just finished scoring a HBO documentary called "New York Says Thank You."</p>',
                    thumb: require('../images/teams/Michael.Leslie.jpg'),
                    category: 'main'
                },
                {
                    id: 9,
                    name: 'Rammath Siva',
                    position: 'Still Photographer',
                    bio: '<p>Ramnath is a banker by day, and photographer at heart. Ram\'s interest for photography took root in college, but jumped to the next level while studying at the Chicago Photography Center.  In 2007, he returned to India and embarked upon travel around the country and the world to capture \'uncliched\' images of the places and people. He credits these nomadic forays with heavly influencing his photography style. He currently resides in Bangalore (India).</p><p>While not entirely minimalistic, Ramnath believes in capturing the essence of a scene or object and leaving out extraneous distractions.  His specialty is everyday street scenes.  As part of shooting for First Sight, Ramnath has also captured images of life in rural India.</p><p>He has previously exhibited his works at galleries and photography shows in the Chicago area including at the Chicago Photography Center, Acme Art Works, 33 Collective Gallery and at Tall Grass Arts Association Gallery. In India, he has exhibited at a two-member show aptly titled Pixels & Palettes at The Collective, Bangalore and has also displayed his works at Gallerie Nvya, Delhi. He has been published in an online art magazine \'Quake\'.  His works can be seen online at <a href="http://www.ramnathsiva.com/" target="_blank">www.ramnathsiva.com</a>.</p>',
                    thumb: require('../images/teams/Ramnath.Siva.2.jpg'),
                    category: 'main'
                },
                {
                    id: 10,
                    name: 'Fernanda Rossi',
                    position: 'Story Consultant',
                    bio: '<p>Whether in a personal session, a workshop or through her writing, Fernanda Rossi supports and guides filmmakers with proven methodologies in the creation and improvement of rough cuts, fundraising trailers, synopses, treatments, scripts and pitches. She has doctored over 300 documentaries, fiction scripts and work samples, including two Academy Award®nominees.</p><p>Fernanda has been invited to give presentations and seminars for major world conferences and organizations such as HotDocs, Sheffield Doc/Fest, and SilverDOCS. Her columns and articles have been published in trade publications like The Independent, Documentary Magazine, and many others. She is also the author of the book that, according to industry professionals is the bible on demo production: Trailer Mechanics: A Guide to Making your Documentary Fundraising Demo. Learn more about her at <a href="http://www.documentarydoctor.com/" target="_blank">www.documentarydoctor.com</a>.</p>',
                    thumb: require('../images/teams/rossi.jpeg'),
                    category: 'sub'
                },
                {
                    id: 11,
                    name: 'Ross Kauffman',
                    position: 'Advisory Board Member',
                    bio: '<p>Ross Kauffman is the director, producer, cinematographer and co-editor of BORN INTO BROTHELS, winner of the 2005 Academy Award for Best Documentary. Other projects include award-winning feature documentary In A Dream (Executive Producer) and Academy Award nominated Postergirl (Consulting Producer). In 2001, Kauffman formed Red Light Films, a New York based production company specializing in narrative and documentary films. Currently, he is working on a variety of projects including; WAIT FOR ME, a feature length documentary chronicling the story of a mother\'s spiritual and emotional search for her son who went missing twenty-three years ago; and BOY BOY GIRL GIRL; a comedic narrative feature film about a gay couple, eager to become parents, trying to adopt a newborn baby from a drug-addicted pregnant woman and her abusive lesbian girlfriend.</p>',
                    thumb: require('../images/teams/kauffman.jpeg'),
                    category: 'sub'
                }
            ]
        }
    },
    computed: {
        consultants: function() {
            return this.teams.filter(function(team) {
                return team.category === 'sub'
            })
        },
        notConsultants: function() {
            return this.teams.filter(function(team) {
                return team.category !== 'sub'
            })
        },
        countNotConsultants: function() {
            // console.log(this.teams.length - 1 - 1);
            return this.teams.length - 1 - 1
        }
    },
    methods: {
        selectedTab(i) {
            this.activeTab = i
        }
    }
}
</script>

<style scoped>
    img {
        width: 100%;
    }
    .tabs__list,
    .tabs__header {
        padding-left: 0;
        margin-bottom: 0;
    }
    .tabs__list,
    .tabs__header {
        list-style: none;
    }
    .tabs__header li {
        /* margin-bottom: 1.4em; */
        cursor: pointer;
        padding: 4px 0;
    }
    .tabs__header li p {
        margin-bottom: 0;
    }
    .tabs__header li p.team-position {
        font: italic 11px Arial, Helvetica, sans-serif;
    }
    /* .tabs__list-tab {
        display: none;
    }
    .tabs__list-tab.active {
        display: initial;
    } */
    .tabs__header li.active,
    .tabs__header li:hover {
        background-image: url(../images/bg-tab-hover.png);
        background-repeat: repeat-y;
        background-position: top right;
        text-decoration: none;
    }
    .tabs__list li h5 {
        margin-bottom: 1.4em;
    }
    .special-team + .special-team {
        margin-top: 40px;
    }
</style>