<template>
    <div id="home" class="fullscreen d-flex justify-content-center"  v-lazy:background-image="image">
        <div class="fullscreen-inner">
            <b-container>
                <b-row cols-sm="12">
                    <b-col class="logo-wrapper">
                        <a href="/"><img v-lazy="logo" alt="" ></a>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
import bg1 from '../images/home-bg.jpeg'
import logo from '../images/logo.png'
export default {
    data() {
        return {
            image: bg1,
            logo: logo
        }
    },
    created() {
        window.addEventListener( 'resize', this.fullWidthScreen )
    },
    mounted() {
        this.fullWidthScreen()
    },
    methods: {
        fullWidthScreen() {
            const div = this.$el,
                windowHeight = window.innerHeight;
                //prevDiv = this.$el.previousSibling.firstChild,
                //prevDivHeight = prevDiv.offsetHeight,
                // newHeight = windowHeight - prevDivHeight;
            div.style.height = windowHeight + 'px'
        }
    }
}
</script>