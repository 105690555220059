<template>
    <div id="screening" class="fullscreen d-flex align-items-center justify-content-center" v-lazy:background-image="image">
        <div class="fullscreen-inner">
            <b-container>
                <b-row cols-sm="12">
                    <b-col class="py-5">
                        <b-card-group deck class="main-content">
                            <b-card
                            header="Upcoming private screening schedule"
                            >
                                <b-card-text>
                                    <strong>FIRST SIGHT PRIVATE SCREENING</strong><br>
                                    Screening made possible by a generous donation from Meera Gandhi and<br>
                                    the Giving Back Foundation and is featured as part of S.H.E. Summit<br>
                                    Week, about women effecting change in the world.
                                </b-card-text>
                                <b-card-text>
                                    NY SCREENING DETAILS:
                                </b-card-text>
                                <b-card-text>
                                    Tuesday, June 19th<br>
                                    6:30 pm Screening<br>
                                    7:30 pm Q&A with filmmakers<br>
                                    Disney Screening Room<br>
                                    500 Park Avenue (@ 59th Street)
                                </b-card-text>
                                <b-card-text>
                                    To buy tickets, please go to <a href="http://rkthb.co/7941" target="_blank">http://rkthb.co/7941</a>
                                </b-card-text>
                                <b-card-text>
                                    Disney Theater in New York City Tuesday, June 19th
                                </b-card-text>
                                <b-card-text>
                                    TV Journalist and Documentary Filmmaker Joya Dass tackles the topic of curable blindness in children in the developing world, in this 50-minute documentary FIRST SIGHT. As the film makes the festival rounds, the next private screening will be held at the Disney Theater in New York City Tuesday, June 19th. "First Sight" features new music from award-winning artists Sivamani and Falu.
                                </b-card-text>
                                <b-card-text>
                                    NY PRIVATE SCREENINGS DETAILS:
                                </b-card-text>
                                <ol>
                                    <li>
                                        "First Sight" screens at the Disney Theater in New York City Tuesday, June 19th 630 pm as part of S.H.E. Summit Week. Screening and evening generously sponsored by author and philanthropist Meera Gandhi and the Giving Back Foundation. <a href="http://thegivingbackfoundation.net/founder.php" target="_blank">http://thegivingbackfoundation.net/founder.php </a>
                                    </li>
                                    <li>Screening with BBQ Films as part of their rooftop series, July 2012</li>
                                </ol>
                                <b-card-text>
                                    To RSVP, please contact Lily O Brien at <a href="mailto:lily@lilygolightlypr.com" target="_blank">lily@lilygolightlypr.com</a>
                                </b-card-text>
                                <b-card-text style="font-style: italic; font-weight: bold;">
                                    Past Screenings
                                </b-card-text>
                                <b-card-text>
                                    <strong>2011</strong>
                                </b-card-text>
                                <b-card-text>
                                    <strong><em>June 17</em></strong><br>
                                    Host: Dr. Lauri Hicks, Center for Disease Control, Atlanta, GA  (<a href="http://www.cdc.gov/" target="_blank">http://www.cdc.gov</a>)
                                </b-card-text>
                                <b-card-text>
                                    <strong><em>June 25</em></strong><br>
                                    Host: Dr. Bala Ambati, Univ. of Utah Moran Eye Ctr, Salt Lake City, UT (<a href="http://uuhsc.utah.edu/moranEyeCenter/faculty/bala_ambati.htm" target="_blank">http://uuhsc.utah.edu/moranEyeCenter/faculty/bala_ambati.htm</a>)
                                </b-card-text>
                                <b-card-text>
                                    <strong><em>July 14</em></strong><br>
                                    Host: Dr. Nimesh Nagarsheth, Mt. Sinai, NYC (<a href="http://www.nedtheband.com/" target="_blank">http://www.nedtheband.com/</a>)
                                </b-card-text>
                                <b-card-text>
                                    <strong><em>July 28</em></strong><br>
                                    Host: Chef Hemant Mathur & Owner Vijay Rao, Tulsi Restaurant, NYC (<a href="http://www.tulsinyc.com/" target="_blank">http://www.tulsinyc.com/</a>)
                                </b-card-text>
                                <b-card-text>
                                    <strong><em>August 5th</em></strong><br>
                                    Host: Amrita Dalal, Ramsey, NJ
                                </b-card-text>
                                <b-card-text>
                                    <strong><em>August 11</em></strong><br>
                                    Host: Lakshmi Puri, Assistant Secretary - General/Deputy Executive Director, and Nanette Braun, United Nations Women (<a href="http://www.unwomen.org/" target="_blank">http://www.unwomen.org/</a>)
                                </b-card-text>
                                <b-card-text>
                                    <strong><em>August 25</em></strong><br>
                                    Host: Dr. Emil Chynn, Park Avenue Laser Vision, NYC  (<a href="http://www.parkavenuelasek.com/" target="_blank">http://www.parkavenuelasek.com/</a>)
                                </b-card-text>
                                <b-card-text>
                                    <strong><em>September 10</em></strong><br>
                                    Rubin Museum, NYC  (<a href="http://www.rmanyc.org/" target="_blank">http://www.rmanyc.org/</a>)
                                </b-card-text>
                                <b-card-text>
                                    <strong><em>December</em></strong><br>
                                    Coimbatore, Bangladore, India (<a href="http://www.sankaraeye.com/" target="_blank">http://www.sankaraeye.com/</a>)
                                </b-card-text>
                            </b-card>
                        </b-card-group>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
import bg1 from '../images/screening-bg.jpeg'

export default {
    data() {
        return {
            image: bg1
        }
    }
}
</script>

<style scoped>
    .d-flex {
        background-attachment: fixed;
    }
</style>