<template>
    <div id="footer">
        <b-container>
            <b-row class="align-items-center">
                <b-col sm="4">
                    <img v-lazy="logo">
                </b-col>
                <b-col sm="4">
                    <p class="copyright">&copy; EYES WIDE OPEN PRODUCTIONS</p>
                </b-col>
                <b-col sm="4">
                    <p class="copyright">Powered by <a href="https://www.siteforbiz.com/" target="_blank">Siteforbiz.com</a></p>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import footerLogo from '../images/logo-footer-1.png'
export default {
    data() {
        return {
            logo: footerLogo
        }
    }
}
</script>

<style scoped>
    #footer {
        padding-top: 5px;
        padding-bottom: 5px;
        background-color: #000;
    }
    .copyright {
        color: #fff;
        margin-bottom: 0;
        text-align: center;
        font-size: 12px;
    }
    .copyright a {
        color: #fff;
    }
</style>