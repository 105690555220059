<template>
	<div id="app">
		<Navigation />
		<Home />
		<About />
		<Presses />
		<Photographies />
		<Trailer />
		<Teams />
		<Kids />
		<Donate />
		<Screening />
		<Footer />
		<!-- <img alt="Vue logo" src="./assets/logo.png">
		<HelloWorld msg="Welcome to Your Vue.js App"/> -->
	</div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import Navigation from './components/navigation'
import Home from './components/Home'
import About from './components/About'
import Presses from './components/Presses'
import Photographies from './components/Photographies'
import Trailer from './components/Trailer'
import Teams from './components/Teams'
import Kids from './components/kids'
import Donate from './components/Donate'
import Screening from './components/Screening'
import Footer from './components/Footer'

export default {
	name: 'App',
	components: {
		// HelloWorld,
		Navigation,
		Home,
		About,
		Presses,
		Photographies,
		Trailer,
		Teams,
		Kids,
		Donate,
		Screening,
		Footer,
	},
	metaInfo: {
		title: 'FirstSight'
	}
}
</script>

<style>
#app {
	font-family: "Times New Roman", Times, serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	margin-top: 60px;
	font-family: 16px;
}
</style>
