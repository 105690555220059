<template>
    <a :href="press.link" :target="target" :class="disabled">
        {{ press.title }}
    </a>
</template>

<script>
export default {
    props: ['press'],
    computed: {
        disabled() {
            return this.press.link === '#' ? 'disabled' : ''
        },
        target() {
            return this.press.link !== '#' ? '_blank' : ''
        }
    }
}
</script>

<style scoped>
    .disabled {
        pointer-events: none;
        cursor: default;
    }
</style>