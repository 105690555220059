<template>
    <div id="about" class="fullscreen d-flex align-items-center justify-content-center" v-lazy:background-image="image">
        <div class="fullscreen-inner">
            <b-container>
                <b-row cols-sm="12">
                    <b-col class="py-5">
                        <b-card-group deck class="main-content">
                            <b-card
                            header="featured"
                            >
                            <b-card-text>Some 45 million people in Southeast Asia live with some visual impairment. Eighty-five percent of these conditions are curable with a simple surgery. Most people, living in western cultures, could go to a doctor and have a cataract removed immediately. We, as filmmakers discover, that many members of India's rural populations don't have the money, access or education, so many just "live with it."</b-card-text>
                            <b-card-text>Amidst the ignorance and neglect, there is a beacon hope.</b-card-text>
                            <b-card-text>In 1977, a team of volunteer doctors started bringing the eyecare to village doorsteps. They travelled weekly to the most remote hamlets of India to set up eye camps to treat and diagnose patients. The folks who needed surgery got operated on. The folks who needed glasses got low-vision aids---all for free.</b-card-text>
                            <b-card-text>"First Sight" follows Ranjith, Anitha and Balaji as they journey from their villages to undergo life-changing eye sugeries. At 15-years-old, Ranjith is severely depressed. Cataracts are clouding his vision in both eyes. Anitha, 5, has a parasite growing in her left eye. It's threatening both her eyesight and her brain function. Balaji is already going blind because of a rare chromosomal disorder. He pledges to keep working to support his family of seven....until he can't. In a country of a billion people, not everyone gets lucky.</b-card-text>
                            <b-card-text>Meet their families, learn about their dreams and engage with the doctors who treat them. Ranjith, Anitha and Balaji demonstrate the power of a community eye care movement that is completely free to those who will accept it.</b-card-text>
                            </b-card>

                        </b-card-group>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
import bg1 from '../images/about-bg.jpeg'
export default {
    data() {
        return {
            image: bg1       }
    },
    created() {
        // window.addEventListener( 'resize', this.fullWidthScreen )
    },
    mounted() {
        // this.fullWidthScreen()
    },
    methods: {
        fullWidthScreen() {
            const div = this.$el,
                windowHeight = window.innerHeight;
                //prevDiv = this.$el.previousSibling.firstChild,
                //prevDivHeight = prevDiv.offsetHeight,
                // newHeight = windowHeight - prevDivHeight;
            div.style.height = windowHeight + 'px'
        }
    }
}
</script>