import Vue from 'vue'
import App from './App.vue'
import VueMeta from 'vue-meta'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueLazyload from 'vue-lazyload'

import './app.scss'

// const Vue = require('vue')
const VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo)
Vue.use(VueMeta)
Vue.use(VueLazyload, {
    lazyComponent: true
})

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
new Vue({
    render: h => h(App),
}).$mount('#app')