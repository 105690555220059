<template>
    <div id="trailer" class="fullscreen d-flex align-items-center justify-content-center" v-lazy:background-image="image">
        <div class="fullscreen-inner">
            <b-container>
                <b-row cols-sm="12">
                    <b-col class="py-5">
                        <b-card-group deck class="main-content">
                            <b-card
                            header="featured"
                            >
                                <b-row class="flex-md-row-reverse tabs">
                                    <b-col md="3">
                                        <ul class="tabs__header">
                                            <li class="tabs__header-item"
                                            v-for="tab in tabs"
                                            :key="tab.id + 'tab' + tab.id"
                                            :class="{ active: activeTab === tab.id }"
                                            v-on:click="selectTab(tab.id)"
                                            >
                                                <div class="tabs__header-item-inner">
                                                    <div class="tab-item">
                                                        <div class="tab-image">
                                                            <img
                                                            v-lazy="tab.thumb">
                                                        </div>
                                                        <div class="tab-title">
                                                            {{ tab.name }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </b-col>
                                    <b-col md="9">
                                        <div class="tabs__container">
                                            <ul class="tabs__list"
                                            ref="tabslist"
                                            >
                                                <li class="tabs__list-tab" 
                                                v-for="(tab, index) in tabs" 
                                                :key="tab.id + index" 
                                                :class="{ active : activeTab === tab.id }"
                                                >
                                                    <h4>{{ tab.name }}</h4>
                                                    <lazy-component>
                                                        <div
                                                        class="embed-container"
                                                        v-html="tab.iframe"
                                                        ></div>
                                                    </lazy-component>
                                                </li>
                                            </ul>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-card-group>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
import bg1 from '../images/trailer-bg.jpeg'
export default {
    data() {
        return {
            activeTab: 1,
            // offsetRight: 0,
            image: bg1,
            tabs: [
                {
                    id: 1,
                    name: 'Movie Trailer',
                    thumb: require('../images/img-15.jpeg'),
                    iframe: '<iframe title="vimeo-player" src="https://player.vimeo.com/video/23829119" frameborder="0" allowfullscreen></iframe>'
                },
                {
                    id: 2,
                    name: 'Director\'s Message',
                    thumb: require('../images/img-13.jpeg'),
                    iframe: '<iframe src="https://www.youtube.com/embed/StwJVo8vvjw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
                },
                {
                    id: 3,
                    name: 'Founder\'s Message',
                    thumb: require('../images/img-14.jpeg'),
                    iframe: '<iframe src="https://www.youtube.com/embed/qb1NCyHk27g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
                },
                {
                    id: 4,
                    name: 'Making of the Music',
                    thumb: require('../images/trailer4.jpeg'),
                    iframe: '<iframe title="vimeo-player" src="https://player.vimeo.com/video/38640429" frameborder="0" allowfullscreen></iframe>'
                }
            ]
        }
    },
    methods: {
        selectTab( id ) {
            // let tabsList = this.$refs.tabsList
            this.activeTab = id
            console.log(this.activeTab)
            // this.offsetRight = tabsList.clientWidth * (id - 1)
        }
    }
}
</script>

<style scoped>
    .tabs__list,
    .tabs__header {
        padding-left: 0;
        margin-bottom: 0;
    }
    .tabs__list,
    .tabs__header {
        list-style: none;
    }
    .tabs__header li {
        /* margin-bottom: 1.4em; */
        cursor: pointer;
    }
    /* .tabs__header li:last-child {
        margin-bottom: 0;
    } */
    .tabs__header li .tab-image {
        margin-bottom: .5em;
    }
    .tabs__header li .tab-image img {
        width: 100%;
    }
    .tabs__header li .tab-title {
        text-transform: uppercase;
        font-size: 12px;
    }
    .tabs__list-tab {
        display: none;
    }
    .tabs__list-tab.active {
        display: initial;
    }
    .tabs__list-tab h4 {
        margin-bottom: 1.5em;
        font-family: "Times New Roman", Times, serif!important;
    }
    .tabs__header-item-inner {
        padding: 15px;
    }
    .active .tabs__header-item-inner,
    .tabs__header li:hover {
        background-color: rgba(255,255,255,0.2);
        box-shadow: 0 0 27px rgba(0,0,0,0.20);
    }
    .active .tabs__header-item-inner .tab-title {
        /* color: #424242; */
    }

</style>