<template>
    <div id="press" class="fullscreen d-flex align-items-center justify-content-center" v-lazy:background-image="image">
        <div class="fullscreen-inner">
            <b-container>
                <b-row cols-sm="12">
                    <b-col class="py-5">
                        <b-card-group deck class="main-content">
                            <b-card
                            header="featured"
                            >
                                <Press v-bind:presses="presses" />
                            </b-card>
                        </b-card-group>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
import Press from './Press'
import bg1 from '../images/press-bg.jpeg'

export default {
    components : {
        Press
    },
    data() {
        return {
            image: bg1,
            presses: [
                {
                    title: 'Indian Express',
                    link: '../pdf/indianexpressarticle.pdf'
                },
                {
                    title: 'Urban Asian',
                    link: 'https://urbanasian.com/whats-happenin/2012/06/exclusive-interview-with-joya-dass/'
                },
                {
                    title: 'Tribeca Citizen',
                    link: 'http://tribecacitizen.com/2012/02/22/tcqa-joya-dass/'
                },
                {
                    title: 'Lassi with Lavina',
                    link: 'http://www.lassiwithlavina.com/24_7_talkischeap/first-sight-seeing-the-world-afresh/html'
                },
                {
                    title: 'Claudi Chan',
                    link: 'http://www.claudiachan.com/interviews/joya-dass/#CareerTransitions'
                },
                {
                    title: 'About.com',
                    link: 'http://documentaries.about.com/u/sty/documentarydirectors/What_Documentary_Are_You_Working_On/FIRST-SIGHT-THE-STORY-OF-RANJITH-KUMAR.htm'
                },
                {
                    title: 'Green Edge NYC',
                    link: 'http://www.greenedgenyc.org/events/sense-sustainability'
                },
                {
                    title: 'Ecouterre',
                    link: 'http://www.ecouterre.com/nyc-event-sense-sustainability-eco-fashion-showfundraiser/'
                },
                {
                    title: 'Treehugger',
                    'link': 'http://www.treehugger.com/files/2010/04/nyc-event-sense-and-sustainability-fashion-show-and-fundraiser-video.php'
                },
                {
                    title: 'Guest of a Guest',
                    link: 'http://guestofaguest.com/calendar/2010/4/sense-sustainability-green-fashion-show-cocktail-soiree/'
                },
                {
                    title: 'Ecorazzi',
                    link: 'http://www.ecorazzi.com/2010/04/02/sense-sustainability-eco-friendly-cocktail-party-raises-money-for-documentary/'
                },
                {
                    title: 'The Purse Page',
                    link: 'http://www.pursepage.com/fashion-news/sense-sustainability-fashion-show-and-benefit-in-nyc-friday.html'
                },
                {
                    title: 'Socially Superlative',
                    link: 'http://sociallysuperlative.com/2010/04/12/sense-sustainability-green-fashion-show-cocktail-soiree-2/'
                },
                {
                    title: 'City.com',
                    link: 'http://www.city.com/'
                },
                {
                    title: 'Swatiar',
                    link: '#'
                },
                {
                    title: 'Time Out New York',
                    link: '#'
                },
                {
                    title: 'Chicgalleria',
                    link: '#'
                },
                {
                    title: 'Ms. Fabulous',
                    link: '#'
                },
                {
                    title: 'Anders Eke Ken',
                    link: '#'
                },
                {
                    title: 'Going NYC',
                    link: '#'
                },
                {
                    title: 'Look Listen Play',
                    link: '#'
                },
                {
                    title: 'City Saheli',
                    link: '#'
                },
                {
                    title: 'A Green Living',
                    link: '#'
                },
                {
                    title: 'Urban Milan',
                    link: '#'
                },
                {
                    title: 'Meet Up',
                    link: '#'
                },
                {
                    title: 'New York MasterPlanner',
                    link: '#'
                },
                {
                    title: 'CharityHappenings.org',
                    link: '#'
                },
                {
                    title: 'Plan Cast',
                    link: '#'
                },
                {
                    title: 'India  Tribune',
                    link: '#'
                },
                {
                    title: 'Medium Reality',
                    link: '#'
                },
                {
                    title: 'Ankara Tarihi',
                    link: '#'
                }
            ]
        }
    },
    created() {
        // window.addEventListener( 'resize', this.fullWidthScreen )
    },
    mounted() {
        // this.fullWidthScreen()
    },
    methods: {
        fullWidthScreen() {
            const div = this.$el,
                windowHeight = window.innerHeight;
                //prevDiv = this.$el.previousSibling.firstChild,
                //prevDivHeight = prevDiv.offsetHeight,
                // newHeight = windowHeight - prevDivHeight;
            div.style.height = windowHeight + 'px'
        }
    }
}
</script>