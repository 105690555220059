<template>
    <div id="kids" class="fullscreen d-flex align-items-center justify-content-center" v-lazy:background-image="image">
        <div class="fullscreen-inner">
            <b-container>
                <b-row cols-sm="12">
                    <b-col class="py-5">
                        <b-card-group deck class="main-content">
                            <b-card
                            header="Kids"
                            >
                                <ul class="kids-list row">
                                    <li
                                    v-for="kid in kids"
                                    :key="kid.id"
                                    class="kid col-4"
                                    >
                                        <a v-b-modal="'modal-'+kid.id">
                                            <img v-lazy="kid.thumb">
                                            <div class="kid-name-wrapper">
                                                <p>{{ kid.name }}</p>
                                            </div>
                                        </a>
                                        <b-modal :id="`modal-${kid.id}`" centered size="xl" hide-footer hide-header no-close-on-backdrop class="modal">
                                            <div class="modal-bg" v-lazy:background-image="kid.bg"></div>
                                            <b-button class="modal-close" @click="$bvModal.hide('modal-'+kid.id)">&times;</b-button>
                                            <b-row>
                                                <b-col
                                                md="4"
                                                offset-md="8"
                                                >
                                                    <div class="modal-description">
                                                        <div class="modal-description-header">
                                                            <h3>{{ kid.name }}</h3>
                                                            <h6>{{ kid.subtitle }}</h6>
                                                        </div>
                                                        <div class="modal-description-content">
                                                            <div v-html="kid.description"></div>
                                                        </div>
                                                    </div>
                                                    
                                                </b-col>
                                            </b-row>
                                        </b-modal>
                                    </li>
                                </ul>
                            </b-card>
                        </b-card-group>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        
    </div>
</template>

<script>
import bg1 from '../images/kids-bg.jpeg'
export default {
    data() {
        return {
            image: bg1,
            kids: [
                {
                    id: 1,
                    name: 'Ranjith',
                    subtitle: 'A prayer for eyes',
                    description: '<p>"I pray for my eyes." That is 15-year-old Ranjith\'s earnest wish. He lives in Thiyagadurgam, a tiny hamlet in Tamil Nadu, a 5 hour drive from the bustling metropolis of Madras. Teenage acne and the hint of a moustache dot his face. His front tooth is missing and he carries his head tilted back, as if still jolted by the pain of when a classmate first elbowed him.  He\'s a sharp contrast to his father, silver-haired like a muscular Bollywood movie villain. Ranjith is slight and unassuming. Everyday, Ranjith and his father walk together to the local temple, where Ranjith prays for his eyesight to get better. If surgery is successful, Ranjith plans to fast for 40 days and go on a grueling pilgrimage to a mountain-top temple.</p>',
                    thumb: require('../images/kids/img-21.jpeg'),
                    bg: require('../images/kids/bg-popup-1.jpeg')
                },
                {
                    id: 2,
                    name: 'Anita',
                    subtitle: 'Marriage is hard enough for kids who are okay',
                    description: '<p>Anitha, five years old, and her family live in a ramshackle hut made of straw and earth.  An open fire with a black urn sits artfully on stones, demarcating the house stove.  Anitha and her mother sit on the dirt floor, cooking a meal and eating together. Her father fetches drinking water from a nearby pond.  In contrast to Ranjith, Anitha  is full of life---and attitude.  A tuft of white flowers, customary decoration for all girls in Tamil Nadu, hangs lopsided from her pigtail. Her severe up-do bares brown round chubby cheeks and a beautiful, endearing face. Her pink dress is neatly pressed, suggesting a certain refinement and self-care that belies her extreme poverty.  She lives in a town called Passar, even more remote and primordial than Ranjith’s village. Anitha’s father Ramalingam is a farmer and a tailor. Her mother works in the fields.   Anitha is only five years old, but her parents already lament her marriage prospects, compounded by her eyesight troubles.  A 4 mm parasitic cyst has developed in her left eye and is getting bigger by the day.  With despair in his voice, Ramalingam admits a dowry is important, but a girl\'s eyes are a far bigger asset. Marriages are difficult enough for completely healthy children, he cries.  Will she get the treatment she needs before its too late?</p>',
                    thumb: require('../images/kids/img-22.jpeg'),
                    bg: require('../images/kids/bg-popup-2.jpeg')
                },
                {
                    id: 3,
                    name: 'Balaji',
                    subtitle: 'I can\'t have big dreams',
                    description: '<p>Balaji is hard to miss in a room.  He sports an 80’s style mullet and a strong jawline.  Mature beyond his years, Balaji was forced to drop out of school due to his vision impairments, and now spends his days as a tailor\'s apprentice.  When asked about his life\'s dreams he responds, matter-of-factly, "with this eye condition, I can’t have big dreams". Tragedy cloaks Balaji\'s life like a dark cape. He\'s only receiving medical attention now because his brother Baburaj attempted suicide. Baburaj and Balaji both suffer from the same rare genetic eye disease and are gradually going blind.  The other tragedy in Balaji’s life is that he may be working underage.  His father is a drunk and spends all his daily wages on his habit, leaving Balaji and his mother to support a family of seven with their work at the Banyan t-shirt factory.</p>',
                    thumb: require('../images/kids/img-23.jpeg'),
                    bg: require('../images/kids/bg-popup-3.jpeg')
                }
            ]
        }
    },
}
</script>

<style scoped>
    .card-body {
        padding: 0!important;
    }
    .kids-list {
        list-style: none;
        margin: 0!important;
        padding-left: 0;
    }
    .kids-list .kid {
        padding: 0;
    }
    .kids-list .kid a {
        position: relative;
        display: block;
        overflow: hidden;
    }
    .kids-list .kid a:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.85);
    }
    .kids-list .kid a:hover:after {
        top: 100%;
    }
    .kids-list .kid img {
        width: 100%;
    }
    .modal .modal-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .modal .modal-close {
        position: absolute;
        top: -20px;
        right: -20px;
        z-index: 4;
        font-size: 40px;
        padding: 10px;
        line-height: 1.2;
        background-color: #000;
        border-color: #000;
    }
    .modal-description {
        background-color: rgba(0,0,0,.85);
    }
    .modal-description-header {
        background-color: #000;
        padding: 20px;
    }
    .modal-description-header * {
        color: #fff;
    }
    .modal-description-header h3,
    .modal-description-header h6 {
        margin-bottom: 0;
    }
    .modal-description-header h3 {
        font-size: 18px;
        text-transform: uppercase;
    }
    .modal-description-header h6 {
        font-size: 14px;
        text-transform: uppercase;
    }
    .modal-description-content {
        padding: 20px;
    }
    .modal-description-content * {
        color: #fff;
        font-size: 11px;
    }
    .modal-description {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        min-height: 450px;
    }
    .kid-name-wrapper {
        padding: 15px;
        background-color: rgba(0,0,0,.85);
        text-align: center;
        position: absolute;
        bottom: 0;
        width: 100%;
    }
    .kid-name-wrapper * {
        color: #fff;
        text-align: center;
    }
    .kid-name-wrapper p {
        margin-bottom: 0;
        font-family: "Times New Roman", Times, serif!important;
        text-transform: uppercase;
        font-size: 1.2rem;
    }
</style>