<template>
    <div>
        <b-navbar toggleable="lg" type="dark" variant="info" fixed="top" >

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
                <b-nav-item href="#" v-scroll-to="'#home'">Home</b-nav-item>
                <b-nav-item href="#" v-scroll-to="'#about'">About</b-nav-item>
                <b-nav-item href="#" v-scroll-to="'#press'">Press/Awards</b-nav-item>
                <b-nav-item href="#" v-scroll-to="'#photography'">Photography</b-nav-item>
                <b-nav-item href="#" v-scroll-to="'#trailer'">Trailer</b-nav-item>
                <b-nav-item href="#" v-scroll-to="'#teams'">Team</b-nav-item>
                <b-nav-item href="#" v-scroll-to="'#kids'">Kids</b-nav-item>
                <b-nav-item href="#" v-scroll-to="'#donate'">Donate</b-nav-item>
                <b-nav-item href="#">Contact</b-nav-item>
                <b-nav-item href="#" v-scroll-to="'#screening'">Screenings</b-nav-item>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
export default {
    mounted() {
        this.getHeight()
    },
    methods: {
        getHeight() {
            const navHeight = this.$el.firstChild.offsetHeight
            const home = this.$el.nextSibling
            home.style.paddingTop = navHeight + 'px'
        }
    }
}
</script>


<style scoped>
    .navbar.navbar-dark {
        background-color: #424242!important;
    }
    @media only screen and (min-width: 992px) {
        .navbar {
            /* position: absolute;
            top: 0;
            left: 0;
            width: 100%; */
        }
        .navbar-collapse {
            justify-content: center;
        }
    }
</style>