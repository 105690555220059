<template>
    <ul>
        <li v-for="press in presses" v-bind:key="press.title">
            <PressItem v-bind:press="press" />
        </li>
    </ul>
</template>

<script>
import PressItem from './PressItem'
export default {
    components: {
        PressItem
    },
    props: ['presses']
}
</script>
<style scoped>
    ul {
        padding-left: 0;
    }
    @media only screen and (min-width: 768px) {
        ul {
            column-count: 2;
            -moz-column-count: 2;
            -webkit-column-count: 2;
        }
    }
    @media only screen and (min-width: 992px) {
        ul {
            column-count: 3;
            -moz-column-count: 3;
            -webkit-column-count: 3;
        }
    }
    li {
        margin-left: 2.25rem;
    }
    a,
    a:hover {
        color: #fff;
    }
</style>