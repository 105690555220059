<template>
    <div id="photography" class="fullscreen d-flex align-items-center justify-content-center" v-lazy:background-image="image">
        <div class="fullscreen-inner">
            <b-container>
                <b-row cols-sm="12">
                    <b-col class="py-5">
                        <b-card-group deck class="main-content">
                            <b-card
                            header="photography"
                            >
                                <div class="sliders">
                                    <VueSlickCarousel
                                        ref="c1"
                                        v-bind="slickOptions1"
                                        class="slider-for"
                                    >
                                        <div v-for="gallery in galleries" v-bind:key="gallery.id">
                                            <div class="image-wrapper">
                                                <img v-lazy="gallery.image" />
                                            </div>
                                        </div>
                                    </VueSlickCarousel>
                                    <VueSlickCarousel
                                        ref="c2"
                                        v-bind="slickOptions2"
                                        class="slider-nav"
                                    >
                                        <div v-for="gallery in galleries" v-bind:key="gallery.id" class="image-bg-wrapper">
                                            <div class="image-bg" v-lazy:background-image="gallery.image">
                                                
                                            </div>
                                        </div>
                                    </VueSlickCarousel>
                                </div>
                            </b-card>
                        </b-card-group>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
import bg1 from '../images/photography-bg.jpeg'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import gallery1 from '../images/slides/slide-1.jpeg'
import gallery2 from '../images/slides/slide-2.jpeg'
import gallery3 from '../images/slides/slide-3.jpeg'
import gallery4 from '../images/slides/slide-4.jpeg'
import gallery5 from '../images/slides/slide-5.jpeg'
import gallery6 from '../images/slides/slide-6.jpeg'
import gallery7 from '../images/slides/slide-7.jpeg'
import gallery8 from '../images/slides/slide-8.jpeg'
import gallery9 from '../images/slides/slide-9.jpeg'
import gallery10 from '../images/slides/slide-10.jpeg'
import gallery11 from '../images/slides/slide-11.jpeg'
import gallery12 from '../images/slides/slide-12.jpeg'
import gallery13 from '../images/slides/slide-13.jpeg'
import gallery14 from '../images/slides/slide-14.jpeg'
import gallery15 from '../images/slides/slide-15.jpeg'
import gallery16 from '../images/slides/slide-16.jpeg'
import gallery17 from '../images/slides/slide-17.jpeg'
import gallery18 from '../images/slides/slide-18.jpeg'
import gallery19 from '../images/slides/slide-19.jpeg'
import gallery20 from '../images/slides/slide-20.jpeg'
import gallery21 from '../images/slides/slide-21.jpeg'
import gallery22 from '../images/slides/slide-22.jpeg'
import gallery23 from '../images/slides/slide-23.jpeg'
import gallery24 from '../images/slides/slide-24.jpeg'
import gallery25 from '../images/slides/slide-25.jpeg'
import gallery26 from '../images/slides/slide-26.jpeg'
import gallery27 from '../images/slides/slide-27.jpeg'
import gallery28 from '../images/slides/slide-28.jpeg'
import gallery29 from '../images/slides/slide-29.jpeg'
import gallery30 from '../images/slides/slide-30.jpeg'
import gallery31 from '../images/slides/slide-31.jpeg'
import gallery32 from '../images/slides/slide-32.jpeg'
import gallery33 from '../images/slides/slide-33.jpeg'
import gallery34 from '../images/slides/slide-34.jpeg'
import gallery35 from '../images/slides/slide-35.jpeg'
import gallery36 from '../images/slides/slide-36.jpeg'
import gallery37 from '../images/slides/slide-37.jpeg'
import gallery38 from '../images/slides/slide-38.jpeg'
import gallery39 from '../images/slides/slide-39.jpeg'
import gallery40 from '../images/slides/slide-40.jpeg'
import gallery41 from '../images/slides/slide-41.jpeg'
import gallery42 from '../images/slides/slide-42.jpeg'
import gallery43 from '../images/slides/slide-43.jpeg'
import gallery44 from '../images/slides/slide-44.jpeg'
import gallery45 from '../images/slides/slide-45.jpeg'
import gallery46 from '../images/slides/slide-46.jpeg'

export default {
    components : {
        VueSlickCarousel
    },
    data() {
        return {
            image: bg1,
            galleries: [
                {
                    id: 1,
                    image: gallery1
                },
                {
                    id: 2,
                    image: gallery2
                },
                {
                    id: 3,
                    image: gallery3
                },
                {
                    id: 4,
                    image: gallery4
                },
                {
                    id: 5,
                    image: gallery5
                },
                {
                    id: 6,
                    image: gallery6
                },
                {
                    id: 7,
                    image: gallery7
                },
                {
                    id: 8,
                    image: gallery8
                },
                {
                    id: 9,
                    image: gallery9
                },
                {
                    id: 10,
                    image: gallery10
                },
                {
                    id: 11,
                    image: gallery11
                },
                {
                    id: 12,
                    image: gallery12
                },
                {
                    id: 13,
                    image: gallery13
                },
                {
                    id: 14,
                    image: gallery14
                },
                {
                    id: 15,
                    image: gallery15
                },
                {
                    id: 16,
                    image: gallery16
                },
                {
                    id: 17,
                    image: gallery17
                },
                {
                    id: 18,
                    image: gallery18
                },
                {
                    id: 19,
                    image: gallery19
                },
                {
                    id: 20,
                    image: gallery20
                },
                {
                    id: 21,
                    image: gallery21
                },
                {
                    id: 22,
                    image: gallery22
                },
                {
                    id: 23,
                    image: gallery23
                },
                {
                    id: 24,
                    image: gallery24
                },
                {
                    id: 25,
                    image: gallery25
                },
                {
                    id: 26,
                    image: gallery26
                },
                {
                    id: 27,
                    image: gallery27
                },
                {
                    id: 28,
                    image: gallery28
                },
                {
                    id: 29,
                    image: gallery29
                },
                {
                    id: 30,
                    image: gallery30
                },
                {
                    id: 30,
                    image: gallery30
                },
                {
                    id: 31,
                    image: gallery31
                },
                {
                    id: 32,
                    image: gallery32
                },
                {
                    id: 33,
                    image: gallery33
                },
                {
                    id: 34,
                    image: gallery34
                },
                {
                    id: 35,
                    image: gallery35
                },
                {
                    id: 36,
                    image: gallery36
                },
                {
                    id: 37,
                    image: gallery37
                },
                {
                    id: 38,
                    image: gallery38
                },
                {
                    id: 39,
                    image: gallery39
                },
                {
                    id: 40,
                    image: gallery40
                },
                {
                    id: 41,
                    image: gallery41
                },
                {
                    id: 42,
                    image: gallery42
                },
                {
                    id: 43,
                    image: gallery43
                },
                {
                    id: 44,
                    image: gallery44
                },
                {
                    id: 45,
                    image: gallery45
                },
                {
                    id: 46,
                    image: gallery46
                }
            ],
            c1: undefined,
            c2: undefined
        }
    },
    mounted() {
        this.c1 = this.$refs.c1
        this.c2 = this.$refs.c2
    },
    computed: {
        slickOptions1() {
            return {
                asNavFor: this.c2,
                focusOnSelect: true,
                swipeToSlide: true
            }
        },
        slickOptions2() {
            return {
                asNavFor: this.c1,
                focusOnSelect: true,
                slidesToShow: 5,
                centerMode: true,
                swipeToSlide: true,
                centerPadding: '60px',
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 3,
                            centerMode: true,
                            swipeToSlide: true,
                            centerPadding: '60px',
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 2,
                            centerMode: false,
                            swipeToSlide: true,
                        }
                    }
                ]
            }
        }
    }
}
</script>

<style scoped>
    .sliders .image-wrapper {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        overflow: hidden;
    }
    .sliders .image-wrapper img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }
    .sliders .image-bg-wrapper {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        overflow: hidden;
    }
    .sliders .image-bg-wrapper .image-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
</style>